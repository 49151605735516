import { Box, FileInput, Group, Loader, Text } from "@mantine/core"
import { IconDownload } from "@tabler/icons"
import { Button } from "antd"
import { disbursementsStore } from "app/core/stores/TableStore"
import { lawyerStore, matterStore, modalStore, whoAmIStore } from "app/core/stores/store"
import { theme } from "app/core/styles/styles"
import { slugify } from "app/core/utils/misc"
import saveAs from "app/core/utils/save"
import createPrintJobDisbursements from "app/disbursements/mutations/createPrintJobDisbursements"
import updateDisbursement from "app/disbursements/mutations/updateDisbursement"
import getAllDisbursements from "app/disbursements/queries/getAllDisbursements"
import { matterPageStore } from "app/pages/organization/matters/[matter]"
import createManyPrintJobs from "app/print-jobs/mutations/createManyPrintJobs"
import getNextPrintJob from "app/print-jobs/queries/getNextPrintJob"
import updateReview from "app/reviews/mutations/updateReview"
import getReviews from "app/reviews/queries/getReviews"
import { invalidateQuery, invoke, useMutation, useQuery, useSession } from "blitz"
import { Disbursement, Review } from "db"
import { isEmpty, isNil, omitBy } from "lodash"
import { runInAction } from "mobx"
import { observer } from "mobx-react-lite"
import moment, { Moment } from "moment"
import Papa from "papaparse"
import { memo, useState } from "react"
import { TTable } from "../table/TTable"
import { LawyerTableFilters } from "../table/cells/filters/Filters"
import { RowActions } from "../table/cells/settings/TSettingsActionCell"
import { StaticLawyerCell } from "../table/cells/static/StaticLawyerCell"
import { SuccessBadge } from "../table/dockets/badges/SuccessBadge"
import { BillingStatusCell } from "../table/dockets/cells/BillingStatusCell"
import { MatterLink, NewOverlapAvatars } from "../table/reviews/PreBillTable"
import { openArchivedDisbursementsModal } from "./ArchivedDisbursementsModal"
import { DisbursementForm, Reviewer } from "./DisbursementForm"
import { LEDES_CODES } from "./DisbursementForm.constants"

export enum TableType {
  WORKSPACE,
  PREBILL,
  BILL,
  REPORT,
}

export const DisbursementsTable = memo(
  (props: { type: TableType; matterId?: number; range?: Moment[]; review?: Review }) => {
    const session = useSession()
    const { type, matterId, range } = props

    const [showBilledAndPaid, setShowBilledAndPaid] = useState(false)

    let where: any = { deleted: false }
    if (type === TableType.PREBILL && props.review) {
      where = { ...where, matterId, prebillingReviewId: props.review.id }
    } else if (type === TableType.REPORT) {
      where = { ...where, matterId, date: { gte: range![0].toDate(), lte: range![1].toDate() } }
      where = omitBy(where, isNil)
    } else if (type === TableType.WORKSPACE) {
      where = { ...where }
      where = omitBy(where, isNil)
    }

    if (!showBilledAndPaid) {
      where = { ...where, OR: [{ billingReviewId: null }, { paid: false }] }
    }

    const [disbursements] = useQuery(getAllDisbursements, {
      where,
      orderBy: { createdAt: "desc" },
    })

    const [updateDisbursementMutation] = useMutation(updateDisbursement, {})
    const [createPrintJobDisbursementsMutation] = useMutation(createPrintJobDisbursements, {})

    const openForm = (record?: Disbursement) => {
      const content = <DisbursementForm initialValues={record} />
      modalStore.setContent("Disbursement", content)
    }

    const PrintForm = () => {
      const [value, setValue] = useState<Record<string, string>[]>()

      const [loading, setLoading] = useState(false)
      const [uploadedCount, setUploadedCount] = useState<number>()

      const [update] = useMutation(createManyPrintJobs, {})
      const [nextPrintJob] = useQuery(getNextPrintJob, {})

      return (
        <Box p={24}>
          <FileInput
            fileInputProps={{ accept: ".csv, text/csv" }}
            onChange={async (file) => {
              setLoading(true)
              const val = Papa.parse(await file?.text(), {
                quotes: false, //or array of booleans
                quoteChar: '"',
                escapeChar: '"',
                delimiter: ",",
                header: true,
                newline: "\n",
                skipEmptyLines: false, //other option is 'greedy', meaning skip delimiters, quotes, and whitespace.
                columns: null, //or array of strings
              })
              setValue(val.data)
              setLoading(false)
            }}
            label="Print log"
          />
          {loading && <Loader />}
          {!isNil(uploadedCount) && (
            <Text>
              Uploaded {uploadedCount} jobs. Disbursements will be automatically created for the
              previous months (not including the current month).
            </Text>
          )}
          <Button
            disabled={!value || loading}
            onClick={async () => {
              setLoading(true)
              if (value) {
                const jobs = value
                  .filter((v) => {
                    if (
                      !v["Login Name"] ||
                      !v["Job Mode"] ||
                      !v["Original Count"] ||
                      !v["Completing Date & Time"]
                    ) {
                      return false
                    }

                    const matterExists = matterStore.findMatterFromFileNumberSuffix(
                      v["Login Name"].trim()
                    )
                    const correctJob =
                      v["Job Mode"].trim() === "Copy" || v["Job Mode"].trim() === "Print"

                    const countGreaterThanZero = parseInt(v["Original Count"].trim()) > 0
                    return (
                      parseInt(v["Job ID"].trim()) > (nextPrintJob?.externalId ?? 0) &&
                      matterExists &&
                      correctJob &&
                      countGreaterThanZero
                    )
                  })
                  .map((v) => ({
                    date: moment(v["Completing Date & Time"], "YYYY-MM-DDTHH:mm:ss").toISOString(),
                    matterId: matterStore.findMatterFromFileNumberSuffix(v["Login Name"].trim())!
                      .id,
                    externalId: parseInt(v["Job ID"].trim()),
                    name: v["File Name"].trim(),
                    pages: parseInt(v["Original Count"].trim()),
                  }))

                await update(jobs)
                setUploadedCount(jobs?.length)
                await createPrintJobDisbursementsMutation()
                setLoading(false)
              }
            }}
          >
            Submit
          </Button>
        </Box>
      )
    }

    const WaveForm = () => {
      const [value, setValue] = useState<Record<string, string>[]>()

      const [loading, setLoading] = useState<boolean>()

      return (
        <Box p={24}>
          <FileInput
            fileInputProps={{ accept: ".csv, text/csv" }}
            onChange={async (file) => {
              setLoading(true)
              const val = Papa.parse(await file?.text(), {
                quotes: false, //or array of booleans
                quoteChar: '"',
                escapeChar: '"',
                delimiter: ",",
                header: true,
                newline: "\n",
                skipEmptyLines: false, //other option is 'greedy', meaning skip delimiters, quotes, and whitespace.
                columns: null, //or array of strings
              })
              setValue(val.data)
              setLoading(false)
            }}
            label="Print log"
          />
          {loading === true && <Loader />}
          {loading === false && <Text>Synced {value?.length} invoices.</Text>}
          <Button
            disabled={!value || loading}
            onClick={async () => {
              setLoading(true)
              if (value) {
                const validPayments = value
                  .filter((v) => {
                    // Filter out invalid entries
                    if (
                      isNil(v["Invoice Number"]) ||
                      isEmpty(v["Invoice Number"]) ||
                      isNil(["Amount Before Sales Tax"]) ||
                      isEmpty(v["Amount Before Sales Tax"])
                    ) {
                      return false
                    }

                    const invoiceNo = parseInt(v["Invoice Number"].trim())
                    if (isNaN(invoiceNo) || invoiceNo <= 0) {
                      return false
                    }

                    const amountBeforeSalesTax = parseFloat(v["Amount Before Sales Tax"].trim())
                    return !(amountBeforeSalesTax >= 0 || isNaN(amountBeforeSalesTax))
                  })
                  .map((v) => ({
                    invoiceNo: parseInt(v["Invoice Number"].trim()),
                    paymentDate: moment(v["Transaction Date"], "YYYY-MM-DD").toDate(),
                  }))

                if (validPayments.length === 0) {
                  setLoading(false)
                  return
                }

                // Get all reviews in one query
                const reviews = await invoke(getReviews, {
                  where: {
                    archived: false,
                    fullyPaid: true,
                    invoiceNo: { in: validPayments.map((p) => p.invoiceNo) },
                  },
                })

                // Prepare bulk update data
                const updateData = validPayments
                  .map((payment) => {
                    const review = reviews.reviews.find((r) => r.invoiceNo === payment.invoiceNo)
                    if (!review) return null

                    // Check if the payment dates are different
                    const existingDate = review.paymentDate
                      ? moment(review.paymentDate).startOf("day")
                      : null
                    const newDate = moment(payment.paymentDate).startOf("day")

                    // Only include if dates are different
                    if (!existingDate || !existingDate.isSame(newDate)) {
                      return {
                        id: review.id,
                        paymentDate: payment.paymentDate,
                      }
                    }
                    return null
                  })
                  .filter((data) => data !== null)

                // Update one at a time for those that need updating
                for (const data of updateData) {
                  await invoke(updateReview, data)
                }

                setLoading(false)
              }
            }}
          >
            Submit
          </Button>
        </Box>
      )
    }

    const openPrintForm = (record?: Disbursement) => {
      const content = <PrintForm />
      modalStore.setContent("Disbursement", content)
    }

    const openWaveForm = (record?: Disbursement) => {
      const content = <WaveForm />
      modalStore.setContent("Invoice sync", content)
    }

    const archive = async (record: Disbursement) => {
      await updateDisbursementMutation({ id: record.id, deleted: true })
      invalidateQuery(getAllDisbursements)
    }

    let action = (
      <Group>
        <Button style={{ marginBottom: 16 }} onClick={() => openForm()}>
          Create disbursement
        </Button>
        <Button style={{ marginBottom: 16 }} onClick={() => openPrintForm()}>
          Upload print log
        </Button>
        {whoAmIStore.me.invitedEmail === "carter@sprigings.com" && (
          <Button style={{ marginBottom: 16 }} onClick={() => openWaveForm()}>
            Upload wave
          </Button>
        )}
        <Button
          style={{ marginBottom: 16 }}
          onClick={() => {
            setShowBilledAndPaid((prev) => !prev)
          }}
        >
          {!showBilledAndPaid ? "Unhide" : "Hide"} billed and paid
        </Button>
        <Button style={{ marginBottom: 16 }} onClick={() => openArchivedDisbursementsModal({})}>
          View archived
        </Button>
      </Group>
    )

    // TODO: should we always let a user create a disbursement?
    if (type !== TableType.WORKSPACE) {
      action = <></>
    }

    const columns = [
      {
        key: "status",
        width: "5%",
        render: (value, record) => {
          return (
            <div style={{ display: "flex", flexDirection: "row" }}>
              <BillingStatusCell record={record as any} />
            </div>
          )
        },
      },
      {
        key: "paid",
        width: "5%",
        render: (value, record) => {
          return (
            <div style={{ display: "flex", flexDirection: "row" }}>
              {record.paid && <SuccessBadge title={"AP Paid"} />}
            </div>
          )
        },
      },
      {
        title: "Date",
        dataIndex: "date",
        key: "date",
        render: (_, record: Disbursement) => <ReactiveDateCell record={record}></ReactiveDateCell>,
        sorter: (a: Disbursement, b: Disbursement) => {
          return moment(a.date).diff(moment(b.date))
        },
      },
      {
        title: "Total",
        dataIndex: "total",
        key: "total",
        render: (_, record: Disbursement) => (
          <div>
            {matterPageStore.currencyLocaleFunction((record.amount ?? 0.0) + (record.tax ?? 0.0))}
          </div>
        ),
        sorter: (a: Disbursement, b: Disbursement) => {
          return a.amount - b.amount
        },
      },
      {
        title: "Before Tax",
        dataIndex: "amount",
        key: "amount",
        render: (_, record: Disbursement) => (
          <div>{matterPageStore.currencyLocaleFunction(record.amount ?? 0.0)}</div>
        ),
        sorter: (a: Disbursement, b: Disbursement) => {
          return a.amount - b.amount
        },
      },
      {
        title: "Tax",
        dataIndex: "tax",
        key: "tax",
        render: (_, record: Disbursement) => (
          <div>{matterPageStore.currencyLocaleFunction(record.tax ?? 0.0)}</div>
        ),
        sorter: (a: Disbursement, b: Disbursement) => {
          return (a.tax ?? 0) - (b.tax ?? 0)
        },
      },
      {
        title: "Reviewers",
        dataIndex: "reviewers",
        key: "reviewers",
        filters: LawyerTableFilters(lawyerStore.lawyersArray),
        onFilter: (value, record: Review) => {
          // Needs to be mapped to a string to play nice.
          return record.reviewers
            ? !isNil((record.reviewers as Array<any>).find((a) => a.id.toString() === value))
            : false
        },
        filterSearch: true,
        render: (value: Array<{ tier: number; value: number }>, record: Disbursement, index) => (
          <NewOverlapAvatars
            handleClick={() => {
              runInAction(() => {
                const reviewer = (record.reviewers as unknown as Reviewer[]).find(
                  (f: Reviewer) => f.id === session.userInOrgId!
                )!
                reviewer.approved = !reviewer.approved
              })
              invoke(updateDisbursement, {
                id: record.id,
                reviewers: record.reviewers,
              })
            }}
            record={record}
            users={lawyerStore.lawyersArray}
          />
        ),
      },
      {
        title: "Creator",
        dataIndex: "userInOrganizationId",
        key: "userInOrganizationId",
        render: (value, record: Disbursement) => (
          <StaticLawyerCell id={record.userInOrganizationId} />
        ),
        sorter: (a: Disbursement, b: Disbursement) => {
          return a.userInOrganizationId - b.userInOrganizationId
        },
      },
      {
        title: "Description",
        dataIndex: "reason",
        key: "reason",
        render: (_, record: Disbursement) => (
          <ReactiveDescriptionCell record={record}></ReactiveDescriptionCell>
        ),
      },
      {
        title: "LEDES",
        dataIndex: "ledgerId",
        key: "ledgerId",
        render: (_, record: Disbursement) => (
          <div>{LEDES_CODES.find((f) => f.value === record.ledgerId)?.label}</div>
        ),
      },
      {
        title: "Request",
        dataIndex: "url",
        key: "url",
        render: (_, record: Disbursement) => {
          if (!record.url) {
            return null
          }
          return (
            <IconDownload
              cursor={"pointer"}
              color={theme.blue[600]}
              size={16}
              onClick={async () => {
                const data = await (await fetch(`/api/s3/download?key=${record.url}`)).blob()
                saveAs(data, "CR-" + slugify(record.reason) + ".pdf")
              }}
            />
          )
        },
      },
      {
        title: "Receipt",
        dataIndex: "receiptUrl",
        key: "receiptUrl",
        render: (_, record: Disbursement) => {
          if (!record.receiptUrl) {
            return null
          }
          return (
            <IconDownload
              cursor={"pointer"}
              color={theme.blue[600]}
              size={16}
              onClick={async () => {
                const data = await (await fetch(`/api/s3/download?key=${record.receiptUrl}`)).blob()
                saveAs(data, "Receipt-" + slugify(record.reason) + ".pdf")
              }}
            />
          )
        },
      },
      {
        title: "Client",
        dataIndex: "clientId",
        key: "clientId",
        render: (value: number, record) => (
          <div>{matterStore.findClientFromMatter(record.matterId)?.name}</div>
        ),
        sorter: (a, b) =>
          matterStore.findClientFromMatter(a.matterId)?.id ??
          0 - (matterStore.findClientFromMatter(b.matterId)?.id ?? 0),
      },
      {
        title: "Matter",
        dataIndex: "matterId",
        key: "matterId",
        render: (_, record: Disbursement) => (
          <ReactiveMatterCell record={record}></ReactiveMatterCell>
        ),
        sorter: (a: Disbursement, b: Disbursement) => {
          return a.matterId - b.matterId
        },
      },
      RowActions(openForm, archive),
    ]

    return (
      <div>
        {action}
        <TTable
          columns={columns}
          empty={{
            title: "No disbursements",
            subtitle: "Create a disbursement for a bill, charge, or receipt.",
            action,
          }}
          dataSource={disbursements}
          store={disbursementsStore}
        />
      </div>
    )
  }
)

const ReactiveDescriptionCell = observer((props: { record: Disbursement }) => {
  const { record } = props
  return <div>{record.reason}</div>
})

export const ReactiveAmountCell = observer((props: { record: Disbursement }) => {
  const { record } = props
  return <div>{matterPageStore.currencyLocaleFunction(record.amount ?? 0.0)}</div>
})

export const ReactiveDateCell = observer((props: { record: Disbursement }) => {
  const { record } = props
  return <div>{moment(record?.date).format("MMM-D-YYYY")}</div>
})

export const ReactiveMatterCell = observer((props: { record: Disbursement }) => {
  const { record } = props
  return (
    <div>
      <MatterLink value={record.matterId}></MatterLink>
    </div>
  )
})
