import { reviewPageStore } from "app/core/stores/ReviewStore"
import { Disbursement, Entry, Review } from "db"
import { useCallback, useEffect, useState } from "react"
import { getPreBillForReviewOrDisbursement } from "./helpers/store"
import { BillingStatus } from "./helpers/types"

export const useReviewStatus = (
  entry: Entry | Disbursement,
  reviews: Review[]
): { status: BillingStatus; invoiceNo: number | null | undefined } => {
  const [review, setReview] = useState<Review | undefined>(undefined)

  const load = useCallback(async () => {
    // Return if non-billable work...
    if ((entry as Entry).task && (entry as Entry).task === "nbw") {
      return
    }
    const review = await getPreBillForReviewOrDisbursement(entry, reviews)
    setReview(review)
  }, [entry, reviews])

  useEffect(() => {
    load()
  }, [load])

  let status: BillingStatus = BillingStatus.UNBILLED
  let invoiceNo: number | null | undefined = undefined

  if ((entry as any).estimate) {
    status = BillingStatus.ESTIMATE
  } else if (
    entry.billingReviewId &&
    // TODO: move this to react hook.
    (reviewPageStore.findReviewById(entry.billingReviewId)?.paymentAttachment || review?.fullyPaid)
  ) {
    status = BillingStatus.COLLECTED
    invoiceNo = reviewPageStore.findReviewById(entry.billingReviewId)?.invoiceNo
  } else if (entry.billingReviewId) {
    status = BillingStatus.FINALIZED
    invoiceNo = reviewPageStore.findReviewById(entry.billingReviewId)?.invoiceNo
  }
  // Only for disbursements...
  else if (review && !review.archived && !entry.prebillingReviewId && !entry.billingReviewId) {
    status = BillingStatus.CAN_ADD
  } else if (entry.prebillingReviewId && !entry.billingReviewId) {
    status = BillingStatus.PREBILLED
    invoiceNo = reviewPageStore.findReviewById(entry.prebillingReviewId)?.invoiceNo
  }

  return { status, invoiceNo }
}
