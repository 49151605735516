export const LEDES_CODES = [
  { value: "E101", label: "E101 - Copying" },
  { value: "E102", label: "E102 - Outside Printing" },
  { value: "E103", label: "E103 - Word Processing" },
  { value: "E104", label: "E104 - Facsimile" },
  { value: "E105", label: "E105 - Telephone" },
  { value: "E106", label: "E106 - Online Research" },
  { value: "E107", label: "E107 - Delivery/Messenger Service" },
  { value: "E108", label: "E108 - Postage" },
  { value: "E109", label: "E109 - Local Travel" },
  { value: "E110", label: "E110 - Out-of-Town Travel" },
  { value: "E111", label: "E111 - Meals" },
  { value: "E112", label: "E112 - Court Fees" },
  { value: "E113", label: "E113 - Subpoena Fees" },
  { value: "E114", label: "E114 - Witness Fees" },
  { value: "E115", label: "E115 - Deposition Transcripts" },
  { value: "E116", label: "E116 - Trial Transcripts" },
  { value: "E117", label: "E117 - Trial Exhibits" },
  { value: "E118", label: "E118 - Litigation Support Vendors" },
  { value: "E119", label: "E119 - Experts" },
  { value: "E120", label: "E120 - Private Investigators" },
  { value: "E121", label: "E121 - Arbitrators/Mediators" },
  { value: "E122", label: "E122 - Local Counsel" },
  { value: "E123", label: "E123 - Other Professionals" },
  { value: "E124", label: "E124 - Other" },
]

export const TAX_OPTIONS = [
  { value: "13", label: "HST", ledes: "H" },
  {
    value: "5",
    label: "GST",
    ledes: "G",
    tooltip: "5% GST (include PST in the before tax amount)",
  },
  { value: "0", label: "NONE", ledes: "E", tooltip: "No Tax" },
  { value: "custom", label: "CUSTOM", ledes: "O", tooltip: "Custom (only refundable GST)" },
] as const

// Type to ensure uniqueness at compile time
type UniqueTaxOptions = typeof TAX_OPTIONS
export type ValueCheck = UniqueTaxOptions[number]["value"]
export type LabelCheck = UniqueTaxOptions[number]["label"]
