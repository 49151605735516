import { lawyerStore, matterStore, whoAmIStore } from "app/core/stores/store"
import updateManyDisbursements from "app/disbursements/mutations/updateManyDisbursements"
import getAllDisbursements from "app/disbursements/queries/getAllDisbursements"
import cancelEntriesReview from "app/entries/mutations/cancelEntriesReview"
import finalizeManyEntries from "app/entries/mutations/finalizeManyEntries"
import getAllEntries from "app/entries/queries/getAllEntries"
import createReview from "app/reviews/mutations/createReview"
import updateReview from "app/reviews/mutations/updateReview"
import { invoke } from "blitz"
import { Review } from "db"
import { isNil, round } from "lodash"
import moment from "moment"
import { calculateTotals } from "./util/calculate"

export async function bill(matterId: number, entryRange: [Date, Date]) {
  const review = await invoke(createReview, {
    matterId,
    entryRange,
  })

  return review
}

export async function finalize(bill: Review) {
  await invoke(finalizeManyEntries, {
    matterId: bill.matterId,
    reviewId: bill.id,
  })

  await invoke(updateManyDisbursements, {
    matterId: bill.matterId,
    reviewId: bill.id,
  })

  // Get all of the entries that were just assigned to the finalized review.
  // TODO: we should add disbursements to this calculation.
  const entries = await invoke(getAllEntries, { where: { billingReviewId: bill.id } })
  const disbursements = await invoke(getAllDisbursements, {
    where: { billingReviewId: bill.id },
  })

  const docketTotals = calculateTotals(
    entries,
    lawyerStore.lawyersArray,
    matterStore.matterArray,
    matterStore.clientArray
  )

  const disbursementTotal = disbursements.reduce((prv, cur) => {
    return round((prv += cur.amount), 2)
  }, 0.0)

  const disbursementTax = disbursements.reduce((prv, cur) => {
    return round((prv += cur.tax ?? 0.0), 2)
  }, 0.0)

  const matter = matterStore.findMatterFromId(bill.matterId)
  const client = matterStore.findClientFromMatter(bill.matterId)

  let discount = 0.0

  if (bill.discount && bill.discount > 0) {
    discount = bill.discount
  } else if (matter?.discount && matter.discount > 0) {
    discount = matter!.discount
  } else if (client?.discount && client.discount > 0) {
    discount = client!.discount
  }

  let writtenOff = 0.0
  if (!isNil(bill.writtenOff)) {
    writtenOff = bill.writtenOff
  }

  // This date should mark the date that the bill was sent to the client.
  // Use the AR date if it exists, otherwise use the current date.
  const arDate = moment(bill.arDate ?? new Date())
    .endOf("day")
    .toDate()

  const finalizedSubtotal = docketTotals.billableAmount
  const discountAmount = round((discount / 100.0) * finalizedSubtotal, 2)

  let docketTax = 0.0
  if (!matter?.taxFree) {
    docketTax = round(
      ((docketTotals.billableAmount - discountAmount - writtenOff) *
        whoAmIStore.me.organization.taxRate) /
        100.0,
      2
    )
  }

  const review = await invoke(updateReview, {
    id: bill.id,
    finalized: true,
    locked: true,
    discount,
    writtenOff,
    feesTax: docketTax,
    feesBeforeTax: docketTotals.billableAmount - discountAmount - writtenOff,
    disbursementsTax: disbursementTax,
    disbursementsBeforeTax: disbursementTotal,
    finalizedBreakdown: docketTotals.byLawyer,
    finalizedSubtotal,
    finalizedHours: docketTotals.billableHours,
    finalizedTax: round(docketTax + disbursementTax, 2),
    finalizedDisbursements: round(disbursementTotal + disbursementTax, 2),
    finalizedTotal: round(
      docketTax +
        disbursementTax +
        docketTotals.billableAmount +
        disbursementTotal -
        discountAmount -
        writtenOff,
      2
    ),
    arDate,
  })

  return review
}

export async function cancelFinalization(bill: Review) {
  // This endpoint cancels ENTRIES and DISBURSEMENTS that were finalized.
  await invoke(cancelEntriesReview, {
    reviewId: bill.id,
  })

  // Move the review back to pre-bills.
  await invoke(updateReview, {
    id: bill.id,
    finalized: false,
    locked: false,
    finalizedHours: 0,
    finalizedSubtotal: 0,
    finalizedTax: 0,
    finalizedDisbursements: 0,
    finalizedTotal: 0,
    finalizedBreakdown: null,
    feesTax: 0,
    feesBeforeTax: 0,
    disbursementsTax: 0,
    disbursementsBeforeTax: 0,
    discount: null,
    writtenOff: 0,
  })

  return
}
