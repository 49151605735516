import { Box, Button, Checkbox, Group } from "@mantine/core"
import { modals } from "@mantine/modals"
import { matterStore, whoAmIStore } from "app/core/stores/store"
import updateDisbursement from "app/disbursements/mutations/updateDisbursement"
import getAllDisbursements from "app/disbursements/queries/getAllDisbursements"
import { matterPageStore } from "app/pages/organization/matters/[matter]"
import { invalidateQuery, invoke, useQuery } from "blitz"
import { Disbursement } from "db"
import { DataTable } from "mantine-datatable"
import moment from "moment"
import { useState } from "react"

interface ArchivedDisbursementsModalProps {}

export const openArchivedDisbursementsModal = ({}: ArchivedDisbursementsModalProps) => {
  modals.open({
    title: "Archived Disbursements",
    size: "xl",
    children: <ArchivedDisbursementsModal />,
  })
}

const ArchivedDisbursementsModal = () => {
  const [archivedDisbursements] = useQuery(getAllDisbursements, {
    where: {
      deleted: true,
      userInOrganizationId: whoAmIStore.me.id,
    },
    orderBy: { createdAt: "desc" },
    take: 24,
  })

  const [selectedRecords, setSelectedRecords] = useState<Disbursement[]>([])
  const [isUnarchiving, setIsUnarchiving] = useState(false)

  return (
    <Box>
      <DataTable
        withColumnBorders
        striped
        columns={[
          {
            accessor: "select",
            title: <Checkbox checked={false} />,
            render: (record) => (
              <Checkbox
                checked={selectedRecords.some((r) => r.id === record.id)}
                onChange={(event) => {
                  if (event.currentTarget.checked) {
                    setSelectedRecords([...selectedRecords, record])
                  } else {
                    setSelectedRecords(selectedRecords.filter((r) => r.id !== record.id))
                  }
                }}
              />
            ),
          },
          {
            accessor: "date",
            title: "Date",
            render: (record) => moment(record.date).format("MMM-D-YYYY"),
          },
          {
            accessor: "reason",
            title: "Description",
          },
          {
            accessor: "amount",
            title: "Amount",
            render: (record) => matterPageStore.currencyLocaleFunction(record.amount ?? 0.0),
          },
          {
            accessor: "matter",
            title: "Matter",
            render: (record) => matterStore.findMatterFromId(record.matterId)?.file,
          },
        ]}
        records={archivedDisbursements}
        idAccessor="id"
      />
      <Group justify="flex-end" mt="md">
        <Button
          onClick={async () => {
            setIsUnarchiving(true)
            try {
              for (const record of selectedRecords) {
                await invoke(updateDisbursement, {
                  id: record.id,
                  deleted: false,
                })
              }
              await invalidateQuery(getAllDisbursements)
              modals.closeAll()
            } catch (error) {
              console.error("Error unarchiving disbursements:", error)
            } finally {
              setIsUnarchiving(false)
            }
          }}
          disabled={selectedRecords.length === 0 || isUnarchiving}
          loading={isUnarchiving}
        >
          Unarchive selected
        </Button>
      </Group>
    </Box>
  )
}
