import {
  Anchor,
  Box,
  Button,
  Checkbox,
  ComboboxItem,
  Divider,
  FileInput,
  Group,
  Loader,
  NumberInput,
  Select,
  Stack,
  TextInput,
  Title,
} from "@mantine/core"
import { openConfirmModal } from "@mantine/modals"
import { DatePicker, Form, FormItem, Input } from "app/core/components/formik/antd"
import { lawyerStore, matterStore, modalStore } from "app/core/stores/store"
import getAllDisbursementTemplates from "app/disbursement-templates/queries/getAllDisbursementTemplates"
import createDisbursement, {
  CreateDisbursement,
} from "app/disbursements/mutations/createDisbursement"
import updateDisbursement from "app/disbursements/mutations/updateDisbursement"
import getAllDisbursements from "app/disbursements/queries/getAllDisbursements"
import { Number, matterPageStore } from "app/pages/organization/matters/[matter]"
import updateReview from "app/reviews/mutations/updateReview"
import getAllReviews from "app/reviews/queries/getAllReviews"
import { invalidateQuery, invoke, useQuery, validateZodSchema } from "blitz"
import cuid from "cuid"
import { Disbursement } from "db"
import { Formik, FormikHelpers } from "formik"
import { isNil, omitBy, round, uniqBy } from "lodash"
import moment from "moment"
import { useCallback, useEffect, useMemo, useState } from "react"
import { MantineMatterSelector } from "../matters/MantineMatterSelector"
import { NewReviewerSelect } from "../table/reviews/PreBillForm"
import { LEDES_CODES, LabelCheck, TAX_OPTIONS } from "./DisbursementForm.constants"

export interface Reviewer {
  id: number
  approved: boolean
}

const useTemplateField = (templateString: string) => {
  const [templateFields, setTemplateFields] = useState<
    { label: string; name: string; value: string }[]
  >([])

  const description = useCallback(
    () =>
      templateString?.replace(/{{(.*?)}}/g, (match) => {
        const templateField = templateFields.find((f) => f.name === match)
        if (templateField && !isNil(templateField.value) && templateField.value !== "") {
          return templateField.value
        }
        return match
      }),
    [templateFields, templateString]
  )

  const setTemplateField = useCallback((name: string, value: string) => {
    setTemplateFields((prev) => {
      return prev.map((f) => {
        if (f.name === name) {
          return { ...f, value }
        }
        return f
      })
    })
  }, [])

  useEffect(() => {
    let newTemplateFields = templateString?.match(/{{(.*?)}}/g)?.map((s) => ({
      label: s?.replace(/{{|}}/g, "")?.trim(),
      name: s,
      value: "",
    }))

    newTemplateFields = uniqBy(newTemplateFields, "name")

    // If there is an existing template field, use its value.
    if (templateFields?.length > 0) {
      newTemplateFields = newTemplateFields?.map((f) => {
        const existing = templateFields.find((t) => t.name === f?.name)
        return existing ? existing : f
      })
    }

    setTemplateFields(newTemplateFields)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateString])

  return { description: description(), templateFields, setTemplateField }
}

const DisbursementTemplateSelector = ({ initialDescription, initialTemplateId, onChange }) => {
  const [data, { isSuccess }] = useQuery(getAllDisbursementTemplates, {})
  const [selected, setSelected] = useState<string>(initialTemplateId)

  let templateString = data?.find((d) => d.id.toString() === selected)?.template ?? ""

  const { description, templateFields, setTemplateField } = useTemplateField(templateString)

  useEffect(() => {
    onChange(description)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [description])

  return (
    <Stack mb={16}>
      <Box>
        <Title order={5}>Description</Title>
        <Box>{description}</Box>
      </Box>

      {isSuccess && (
        <Select
          comboboxProps={{ withinPortal: false }}
          onChange={(s) => {
            setSelected(s ?? "")
            onChange(data?.find((d) => d.id?.toString() === s?.toString())?.template ?? "")
          }}
          defaultValue={initialTemplateId?.toString()}
          searchable
          data={data?.map((d) => ({
            value: d.id.toString(),
            label: d.name,
          }))}
        ></Select>
      )}

      <Anchor target="_blank" size="xs" href="/organization/dtemplates">
        Create more disbursement templates
      </Anchor>

      {templateFields?.map(({ name, value, label }) => {
        return (
          <Box key={name}>
            <label>{label}</label>
            <TextInput
              value={value}
              onChange={(input) => {
                setTemplateField(name, input.currentTarget.value)
              }}
            ></TextInput>
          </Box>
        )
      })}
    </Stack>
  )
}

export const DisbursementForm = ({
  initialValues = {},
}: {
  initialValues?: Partial<Disbursement>
}) => {
  const lawyers = lawyerStore.lawyersArray

  const [matters, setMatters] = useState<number[]>(
    initialValues?.matterId ? [initialValues.matterId] : []
  )
  const [splitAmounts, setSplitAmounts] = useState(false)
  const [amount, setAmount] = useState(initialValues?.amount ?? 0.0)
  const [tax, setTax] = useState(initialValues?.tax ?? 0.0)
  const [ledesCode, setLedesCode] = useState<string>(initialValues?.ledgerId ?? "E124")
  const [taxType, setTaxType] = useState<LabelCheck>(
    (initialValues?.taxType as LabelCheck) ?? "HST"
  )

  const [isLoadingFileChequeRequest, setIsLoadingFileChequeRequest] = useState(false)
  const [isLoadingFilePaymentReceipt, setIsLoadingFilePaymentReceipt] = useState(false)

  let formatted: any = useMemo(() => {
    if (initialValues) {
      return {
        ...initialValues,
        date: initialValues.date ? moment(initialValues.date) : undefined,
        dateServiceRendered: initialValues.dateServiceRendered
          ? moment(initialValues.dateServiceRendered)
          : undefined,
        dateInvoiced: initialValues.dateInvoiced ? moment(initialValues.dateInvoiced) : undefined,
        datePaid: initialValues.datePaid ? moment(initialValues.datePaid) : undefined,
        ledgerId: initialValues.ledgerId ?? "E124",
      }
    }
    return undefined
  }, [initialValues])

  const initialReviewers = useMemo(
    () => (initialValues?.reviewers as Reviewer[] | undefined)?.map((r: Reviewer) => r.id),
    [initialValues?.reviewers]
  )

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
          padding: "24px",
          height: "100px",
        }}
      >
        <Number
          subtitle={matterPageStore.currencyLocaleFunction(amount ?? 0)}
          title={tax ? "Before Tax" : "Untaxed"}
        />
        {(tax ?? 0) > 0 && (
          <>
            <Divider style={{ height: "55px" }} orientation="vertical" />
            <Number subtitle={matterPageStore.currencyLocaleFunction(tax ?? 0)} title={"Tax"} />
          </>
        )}
      </div>
      <Divider style={{ margin: 0, padding: 0 }} />

      <Formik
        initialValues={formatted}
        validate={async (values) => {
          const valid = await validateZodSchema(CreateDisbursement)({
            ...values,
            tax,
            matter: matters[0],
          })
          return valid
        }}
        onSubmit={async (values, formikHelpers: FormikHelpers<{}>) => {
          if (taxType === "CUSTOM" && !tax) {
            alert("Tax is required if tax type is custom")
            return
          }

          // Tax cannot be geater than 13%
          if (tax > round(amount * 0.13, 2)) {
            // This is because Wave has a 13% tax rate set, and higher will break invoice syncing.
            alert("Tax cannot be greater than 13%")
            return
          }

          if (!ledesCode) {
            alert("LEDES code is required")
            return
          }

          if (matters.length === 0) {
            alert("At least one matter must be selected")
            return
          }

          // Calculate amounts if splitting
          const matterCount = matters.length
          const splitAmount = splitAmounts ? round(amount / matterCount, 2) : amount
          const splitTax = splitAmounts ? round(tax / matterCount, 2) : tax

          try {
            if (initialValues.id) {
              await invoke(
                updateDisbursement,
                omitBy(
                  {
                    id: initialValues.id,
                    ...values,
                    amount,
                    tax: tax ?? 0.0,
                    taxType,
                    matterId: matters[0], // Use first matter since we're updating
                    date: moment(values.date).toDate(),
                  },
                  isNil
                )
              )
            } else {
              // Create a disbursement for each selected matter
              for (const matterId of matters) {
                const disbursement = await invoke(
                  createDisbursement,
                  omitBy(
                    {
                      ...values,
                      amount: splitAmount,
                      tax: splitTax ?? 0.0,
                      taxType,
                      matterId,
                      date: moment(values.date).toDate(),
                    },
                    isNil
                  )
                )

                if (!disbursement.estimate) {
                  const existingReviews = await invoke(getAllReviews, {
                    where: {
                      matterId: matterId,
                      finalized: false,
                      archived: false,
                    },
                  })

                  if (existingReviews.length > 0) {
                    openConfirmModal({
                      title: `We found an existing pre-bill for ${
                        matterStore.findMatterFromId(matterId)?.file
                      }`,
                      children: `Would you like to add this disbursement to the existing pre-bill?`,
                      onConfirm: async () => {
                        // Get the newly created disbursement for this matter
                        // Note: You may need to add a query to fetch the latest disbursement by matterId
                        await invoke(updateReview, {
                          id: existingReviews[0].id,
                          addDisbursementPrebill: [disbursement.id],
                        })
                      },
                      labels: {
                        confirm: "Add",
                        cancel: "Skip",
                      },
                    })
                  }
                }
              }
            }

            formikHelpers.resetForm()
            modalStore.setVisibility(false)
            invalidateQuery(getAllDisbursements)
          } catch (err) {
            console.log(err)
          }
        }}
      >
        {(formik) => (
          <Form initialValues={formatted} layout="vertical">
            <div style={{ padding: "24px" }}>
              {initialValues.reason && initialValues.reason != "" ? (
                <FormItem label="Description" name="reason">
                  <Input type="text" name="reason" />
                </FormItem>
              ) : (
                <DisbursementTemplateSelector
                  onChange={(reason) => {
                    formik.setFieldValue("reason", reason)
                  }}
                  initialDescription={initialValues.reason}
                  initialTemplateId={initialValues.disbursementTemplateId}
                ></DisbursementTemplateSelector>
              )}

              {!initialValues.billingReviewId && (
                <>
                  <FormItem
                    tooltip={"Is this amount an estimate for CAD?"}
                    label="Is this an estimate?"
                    name="estimate"
                  >
                    <Checkbox
                      defaultChecked={initialValues.estimate}
                      onChange={(e) => {
                        formik.setFieldValue("estimate", e.target.checked)
                      }}
                      name="estimate"
                    />
                  </FormItem>

                  <FormItem
                    tooltip={"This amount should not include the tax, if any."}
                    label="Before Tax"
                    name="amount"
                  >
                    <NumberInput
                      autoFocus
                      max={10e9}
                      min={0}
                      name={"amount"}
                      prefix="CA$"
                      value={amount}
                      onChange={(value) => {
                        const numValue = typeof value === "number" ? value : parseFloat(value)
                        setAmount(numValue)
                        if (taxType !== "CUSTOM") {
                          setTax(
                            round(
                              numValue *
                                (parseFloat(
                                  TAX_OPTIONS.find((t) => t.label === taxType)?.value ?? "0"
                                ) /
                                  100),
                              2
                            )
                          )
                        }
                      }}
                    />
                  </FormItem>

                  <FormItem label="Tax" name="taxType">
                    <Group w="100%">
                      <Select
                        comboboxProps={{ withinPortal: false }}
                        value={TAX_OPTIONS.find((t) => t.label === taxType)?.value}
                        onChange={(e) => {
                          if (!isNil(e)) {
                            if (e === "CUSTOM") {
                              setTaxType("CUSTOM")
                              setTax(0)
                              return
                            }
                            setTaxType(TAX_OPTIONS.find((t) => t.value === e)?.label ?? "HST")
                            setTax(round(amount * (parseFloat(e) / 100), 2))
                          }
                        }}
                        data={TAX_OPTIONS as unknown as ComboboxItem[]}
                      />
                      {taxType && (
                        <>
                          <NumberInput
                            value={tax}
                            onChange={(value) => {
                              const numValue = typeof value === "number" ? value : 0
                              if (taxType === "CUSTOM" && amount > 0) {
                                setTax(numValue)
                              }
                            }}
                            disabled={taxType !== "CUSTOM"}
                            min={0}
                            step={0.01}
                          />
                          {taxType === "CUSTOM" && (
                            <Box size="sm" style={{ color: "gray" }}>
                              Only include *refundable* GST/HST in the custom amount. Other taxes,
                              including PST/QST should be included in the before tax amount.
                            </Box>
                          )}
                        </>
                      )}
                    </Group>
                  </FormItem>
                </>
              )}

              {!initialValues.prebillingReviewId && !initialValues.billingReviewId && (
                <>
                  <Box my={"lg"}>
                    <MantineMatterSelector
                      multiple={true}
                      onChange={(selectedMatters) => setMatters(selectedMatters.map((m) => m.id))}
                      value={matters.map((m) => m.toString())}
                    />
                  </Box>

                  {matters.length > 1 && (
                    <FormItem
                      tooltip="When checked, the amount and tax will be divided equally among selected matters"
                      label="Split amounts among matters"
                      name="splitAmounts"
                    >
                      <Checkbox
                        checked={splitAmounts}
                        onChange={(e) => setSplitAmounts(e.target.checked)}
                      />
                    </FormItem>
                  )}
                </>
              )}

              {!initialValues.billingReviewId && (
                <NewReviewerSelect
                  onChange={(ids) => {
                    // Update the reviewers field with the new ids, while preserving the approved status of the old reviewers.
                    const newReviewers = ids.map((id) => {
                      let reviewer = formik.initialValues?.reviewers?.find((r) => r.id === id)
                      return reviewer ? reviewer : { id, approved: false }
                    })
                    formik.setFieldValue("reviewers", newReviewers)
                  }}
                  initialValues={initialReviewers}
                  users={lawyers}
                />
              )}

              <FormItem label="LEDES Code" name="ledgerId">
                <Select
                  comboboxProps={{ withinPortal: false }}
                  placeholder="Select a LEDES code"
                  searchable
                  required
                  value={ledesCode}
                  onChange={(value) => {
                    setLedesCode(value ?? "")
                    formik.setFieldValue("ledgerId", value)
                  }}
                  data={LEDES_CODES}
                />
              </FormItem>

              <FormItem label="Date For Internal Purposes" name="date">
                <DatePicker
                  format={"MMM-D-YYYY"}
                  name="date"
                  onChange={(date) => {
                    formik.setFieldValue("date", date)
                    formik.setFieldValue("dateServiceRendered", date)
                    formik.setFieldValue("dateInvoiced", date)
                  }}
                />
              </FormItem>

              <FormItem label="Date Service Rendered" name="dateServiceRendered">
                <DatePicker
                  value={formik.values?.dateServiceRendered}
                  format={"MMM-D-YYYY"}
                  name="dateServiceRendered"
                />
              </FormItem>

              <FormItem label="Date Invoiced" name="dateInvoiced">
                <DatePicker
                  value={formik.values?.dateInvoiced}
                  format={"MMM-D-YYYY"}
                  name="dateInvoiced"
                />
              </FormItem>

              <FormItem label="Cheque Request" name="url">
                <FileInput
                  accept="application/pdf"
                  defaultValue={formik.initialValues?.url}
                  disabled={isLoadingFileChequeRequest}
                  rightSection={isLoadingFileChequeRequest ? <Loader size={12} /> : undefined}
                  multiple={false}
                  placeholder={formik.initialValues?.url ? "Update existing file" : "Select file"}
                  onChange={async (file) => {
                    setIsLoadingFileChequeRequest(true)
                    const uid = cuid()
                    const res = await fetch(`/api/s3/presign?key=${uid}`, {
                      headers: { "content-type": "application/xml" },
                    })
                    const { url, fields } = await res.json()
                    const formData = new FormData()
                    Object.entries({ ...fields, file }).forEach(([key, value]) => {
                      formData.append(key, value as string | Blob)
                    })
                    await fetch(url, {
                      method: "POST",
                      body: formData,
                    })
                    formik.setFieldValue("url", uid)
                    setIsLoadingFileChequeRequest(false)
                  }}
                />
              </FormItem>

              <FormItem label="Date Paid" name="datePaid">
                <DatePicker format={"MMM-D-YYYY"} name="datePaid" />
              </FormItem>

              <FormItem label="Payment Receipt" name="receiptUrl">
                <FileInput
                  accept="application/pdf"
                  disabled={isLoadingFilePaymentReceipt}
                  rightSection={isLoadingFilePaymentReceipt ? <Loader size={12} /> : undefined}
                  defaultValue={formik.initialValues?.receiptUrl}
                  multiple={false}
                  placeholder={
                    formik.initialValues?.receiptUrl ? "Update existing file" : "Select file"
                  }
                  onChange={async (file) => {
                    setIsLoadingFilePaymentReceipt(true)
                    const uid = cuid()
                    const res = await fetch(`/api/s3/presign?key=${uid}`, {
                      headers: { "content-type": "application/xml" },
                    })
                    const { url, fields } = await res.json()
                    const formData = new FormData()
                    Object.entries({ ...fields, file }).forEach(([key, value]) => {
                      formData.append(key, value as string | Blob)
                    })
                    await fetch(url, {
                      method: "POST",
                      body: formData,
                    })
                    formik.setFieldValue("receiptUrl", uid)
                    setIsLoadingFilePaymentReceipt(false)
                  }}
                />
              </FormItem>

              <FormItem
                tooltip={
                  "Has the vendor been paid? Check the box for faxes, photocopies, USBs, etc."
                }
                label="Has the vendor been paid?"
                name="paid"
              >
                <Checkbox
                  description="Only check this box once we have sent payment to the vendor AND you have attached a payment receipt at the bottom of the form. You may check this box without a payment receipt if the charge is from the firm itself, i.e. faxes, photocopies, USBs, etc."
                  defaultChecked={initialValues.paid}
                  onChange={(e) => {
                    formik.setFieldValue("paid", e.target.checked)
                  }}
                  name="paid"
                />
              </FormItem>
            </div>

            <Divider style={{ margin: 0 }} />
            <div
              style={{
                display: "flex",
                gap: "10px",
                padding: "20px",
                justifyContent: "flex-end",
              }}
            >
              <Button
                variant="outline"
                onClick={() => {
                  modalStore.setVisibility(false)
                }}
              >
                Cancel
              </Button>
              <Button
                loading={formik.isSubmitting}
                disabled={
                  isLoadingFileChequeRequest || isLoadingFilePaymentReceipt || formik.isSubmitting
                }
                htmlType="submit"
                onClick={() => {
                  formik.submitForm()
                }}
              >
                Submit
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}
