import { MultiSelect, MultiSelectProps, Skeleton } from "@mantine/core"
import getMatters from "app/matters/queries/getMatters"
import { useQuery } from "blitz"
import { Client, Matter } from "db"
import { memo, useMemo } from "react"

interface MatterWithClient extends Matter {
  client: Client
}

interface MatterSelectorProps extends Omit<MultiSelectProps, "data" | "onChange" | "value"> {
  onChange?: (matters: MatterWithClient[]) => void
  value?: string[]
}

export const MantineMatterSelector = memo(
  ({ onChange, value = [], ...props }: MatterSelectorProps) => {
    const [matters, { isLoading }] = useQuery(getMatters, {
      include: {
        client: true,
      },
      take: 10000,
    })

    const matterOptions = useMemo(() => {
      if (!matters?.matters) return []

      // Group matters by client
      const groupedMatters = matters?.matters?.reduce((acc, matter: MatterWithClient) => {
        const clientName = matter.client.name
        if (!acc[clientName]) {
          acc[clientName] = []
        }
        acc[clientName].push(matter)
        return acc
      }, {} as Record<string, MatterWithClient[]>)

      // Convert to options format
      return Object.entries(groupedMatters).flatMap(([clientName, clientMatters]) => ({
        group: clientName,
        items: clientMatters.map((matter) => ({
          value: matter.id.toString(),
          label: `${matter.file} - ${matter.description}`,
        })),
      }))
    }, [matters])

    const handleChange = (selectedValues: string[]) => {
      if (!onChange) return

      const selectedMatters = selectedValues
        .map((v) => matters?.matters?.find((m) => m.id.toString() === v))
        .filter((m): m is MatterWithClient => !!m)

      onChange(selectedMatters)
    }

    if (isLoading || !matterOptions) {
      return <Skeleton height={32} />
    }

    return (
      <MultiSelect
        comboboxProps={{
          withinPortal: true,
          zIndex: 9999,
        }}
        searchable
        clearable
        data={matterOptions}
        placeholder="Select matters"
        value={value}
        onChange={handleChange}
        maxDropdownHeight={400}
        disabled={isLoading}
        //   filter={(options, search) => {
        //     if (!search) return true
        //     const search = search.toLowerCase().trim()

        //     return options.label.toLowerCase().includes(search)
        //   }}
        {...props}
      />
    )
  }
)
