import { CheckIcon } from "@heroicons/react/solid"
import { Box, Button, Group, Radio, Skeleton, Stack } from "@mantine/core"
import { DatePickerInput } from "@mantine/dates"
import { modals } from "@mantine/modals"
import { Avatar, Popconfirm } from "antd"
import { prebillStore } from "app/core/stores/TableStore"
import { lawyerStore, matterStore, modalStore, whoAmIStore } from "app/core/stores/store"
import { theme } from "app/core/styles/styles"
import { disbursementsWhere } from "app/core/utils/where"
import unbillManyDisbursements from "app/disbursements/mutations/unbillManyDisbursements"
import getAllDisbursements from "app/disbursements/queries/getAllDisbursements"
import getAllEntries from "app/entries/queries/getAllEntries"
import { ExportTemplateSection } from "app/pages/organization/matters"
import { EditingPermissions } from "app/permissions/Simple"
import createReview from "app/reviews/mutations/createReview"
import updateReview from "app/reviews/mutations/updateReview"
import getAllReviews from "app/reviews/queries/getAllReviews"
import getReviews from "app/reviews/queries/getReviews"
import { Link, invalidateQuery, invoke, useMutation, useQuery, useRouter, useSession } from "blitz"
import { Disbursement, Matter, MembershipRole, Review, UserInOrganization } from "db"
import { isNil, uniqBy } from "lodash"
import { DataTable } from "mantine-datatable"
import { runInAction, toJS } from "mobx"
import { observer } from "mobx-react-lite"
import moment from "moment"
import { memo, useState } from "react"
import { TButton } from "../../button/TButton"
import { Reviewer } from "../../disbursements/DisbursementForm"
import { TTable } from "../TTable"
import { LawyerFilterReview } from "../cells/filters/Filters"
import { THeaderPadded } from "../cells/headers/THeaders"
import { ActionType, RowActions } from "../cells/settings/TSettingsActionCell"
import { TTag } from "../settings/TClientSettingsTable"
import { DocketRangeCell } from "./DocketRangeCell"
import { PreBillForm } from "./PreBillForm"
import { ReviewButton } from "./ReviewButton"
import { finalize } from "./bill.helpers"

export const MatterLink = (props: { value: number }) => {
  const session = useSession()
  const matter = matterStore.findMatterFromId(props.value)
  const client = matterStore.findClientFromMatter(props.value)

  if (session.orgRole === MembershipRole.USER) {
    return (
      <div>
        {matter?.file} ({matter?.description})
      </div>
    )
  }

  return (
    <div>
      <Link href={`/organization/clients/${client?.id}`}>
        <div style={{ cursor: "pointer", color: theme.blue[600], fontWeight: 500 }}>
          {matter?.file} ({matter?.description})
        </div>
      </Link>
    </div>
  )
}

export const OverlapAvatars = observer(
  (props: { review: Review; lawyers: UserInOrganization[]; lawyerIds: number[] }) => {
    const session = useSession()
    const review = props.review

    const myself = props.lawyerIds.includes(whoAmIStore.me.id)
    const myselfComplete = review?.reviewerIdsComplete.includes(whoAmIStore.me.id)

    const background = (lawyerId: number) => {
      if (review?.reviewerIdsComplete?.includes(lawyerId)) {
        return { backgroundColor: theme.green[400] }
      } else {
        return { backgroundColor: theme.gray[500] }
      }
    }

    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        {myself && (
          <div
            onClick={() => {
              let ids: any[] = []
              if (review?.reviewerIdsComplete?.includes(session.userInOrgId!)) {
                ids = review?.reviewerIdsComplete?.filter((f) => f !== session.userInOrgId!)
              } else {
                ids = [session.userInOrgId!, ...(review?.reviewerIdsComplete ?? [])]
              }
              // Update the local rows...
              runInAction(() => {
                review!.reviewerIdsComplete = ids
              })
              invoke(updateReview, {
                id: review!.id,
                reviewerIdsComplete: ids,
              })
            }}
          >
            <Avatar
              style={{
                cursor: "pointer",
                // fontSize: "9px",
                border: "2px solid white",
                lineHeight: "28px",
                height: "32px",
                width: "32px",
                fontSize: "10px",
                fontWeight: "600",
                color: "white",
                backgroundColor: myselfComplete ? theme.blue[600] : theme.gray[500],
              }}
            >
              <div
                style={{
                  height: "28px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <CheckIcon height="16px" />
              </div>
            </Avatar>
          </div>
        )}
        <Avatar.Group>
          {props.lawyerIds.map((i, index) => {
            return (
              <Avatar
                key={index}
                size={"large"}
                style={{
                  border: "2px solid white",
                  lineHeight: "28px",
                  height: "32px",
                  width: "32px",
                  fontSize: "10px",
                  fontWeight: "600",
                  color: "white",
                  ...background(i),
                }}
              >
                {/* Use the passed in `lawyers` because they are sorted by tier */}
                {props.lawyers.find((l) => l.id === i)?.shortName.toUpperCase()}
              </Avatar>
            )
          })}
        </Avatar.Group>
      </div>
    )
  }
)

type UserReviewer = Partial<UserInOrganization> & Reviewer

export const NewOverlapAvatars = observer(
  ({
    record,
    users,
    handleClick,
  }: {
    record: Disbursement
    users: UserInOrganization[]
    handleClick
  }) => {
    const userReviewers =
      (record.reviewers as unknown as Reviewer[] | undefined)?.map((reviewer) => {
        const userReviewer: UserReviewer = {
          ...reviewer,
          ...(users.find((u) => u.id === reviewer.id) ?? {}),
        }
        return userReviewer
      }) ?? []

    const myself = userReviewers.find((r) => r.id === whoAmIStore.me.id)
    const myselfComplete = myself?.approved ?? false

    const background = (lawyerId: number) => {
      if (userReviewers.find((r) => r.id === lawyerId)?.approved) {
        return { backgroundColor: theme.green[400] }
      } else {
        return { backgroundColor: theme.gray[500] }
      }
    }

    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        {myself && (
          <div onClick={handleClick}>
            <Avatar
              style={{
                cursor: "pointer",
                border: "2px solid white",
                lineHeight: "28px",
                height: "32px",
                width: "32px",
                fontSize: "10px",
                fontWeight: "600",
                color: "white",
                backgroundColor: myselfComplete ? theme.blue[600] : theme.gray[500],
              }}
            >
              <div
                style={{
                  height: "28px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <CheckIcon height="16px" />
              </div>
            </Avatar>
          </div>
        )}
        <Avatar.Group>
          {userReviewers.map((user, index) => {
            return (
              <Avatar
                key={index}
                size={"large"}
                style={{
                  border: "2px solid white",
                  lineHeight: "28px",
                  height: "32px",
                  width: "32px",
                  fontSize: "10px",
                  fontWeight: "600",
                  color: "white",
                  ...background(user.id),
                }}
              >
                {user?.shortName?.toUpperCase()}
              </Avatar>
            )
          })}
        </Avatar.Group>
      </div>
    )
  }
)

export const findAllDisbursements = async (matterId: number, start: Date, end: Date) => {
  const disbursements = await invoke(getAllDisbursements, disbursementsWhere(matterId, start, end))
  return disbursements
}

export const PreBillWrapper = (props: {}) => {}

export const GeneratePreviousMonthModal = () => {
  const [selectedMatters, setSelectedMatters] = useState<number[]>([])
  const [reviewerType, setReviewerType] = useState<"workspace" | "creators">("workspace")
  const [dueDate, setDueDate] = useState(moment().date(15))
  const [loading, setLoading] = useState(false)

  // Calculate previous month's date range
  const prevMonth = moment().subtract(1, "month")
  const startDate = moment(prevMonth).startOf("month").startOf("day")
  const endDate = moment(prevMonth).endOf("month").endOf("day")

  // Get matters with unbilled entries from previous month
  const [results] = useQuery(getAllEntries, {
    where: {
      date: {
        lte: endDate.toDate(),
      },
      prebillingReviewId: null,
      billingReviewId: null,
      deleted: false,
      posted: {
        not: null,
      },
      task: {
        not: "nbw",
      },
    },
    select: {
      matter: {
        select: {
          id: true,
          file: true,
          description: true,
        },
      },
      userInOrganization: {
        select: {
          id: true,
        },
      },
    },
  }) as unknown as [{ matter: Matter; userInOrganization: UserInOrganization }[]]

  const matters = uniqBy(results, (r) => r.matter.id).map((r) => r.matter)

  const [createReviewMutation] = useMutation(createReview)

  const handleSubmit = async () => {
    setLoading(true)
    try {
      for (const matter of selectedMatters) {
        const reviewers =
          reviewerType === "workspace"
            ? lawyerStore.activeLawyers.map((lawyer, index) => ({
                tier: 2,
                value: lawyer.id,
              }))
            : uniqBy(
                results.filter((r) => r.matter.id === matter).map((r) => r.userInOrganization.id),
                (id) => id
              ).map((id) => ({
                tier: 2,
                value: id,
              }))

        await createReviewMutation({
          matterId: matter,
          entryRange: [startDate.toDate(), endDate.toDate()],
          reviewers,
          due: dueDate.toDate(),
          endDate: endDate.toDate(),
        })
      }

      invalidateQuery(getReviews)
      invalidateQuery(getAllReviews)
      modalStore.setVisibility(false)
    } catch (error) {
      console.error("Failed to create pre-bills:", error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Stack>
      <DatePickerInput
        type="range"
        label="Bill Range"
        value={[startDate.toDate(), endDate.toDate()]}
        disabled
      />

      <DataTable
        withColumnBorders
        striped
        highlightOnHover
        columns={[
          { accessor: "file", title: "Matter" },
          { accessor: "description", title: "Description" },
        ]}
        records={matters}
        selectedRecords={matters.filter((m) => selectedMatters.includes(m.id))}
        onSelectedRecordsChange={(records) => setSelectedMatters(records.map((r) => r.id))}
        fetching={isNil(results)}
        loaderType="oval"
        loaderSize="xs"
      />

      <Radio.Group
        label="Reviewer Selection"
        value={reviewerType}
        onChange={(value) => setReviewerType(value as "workspace" | "creators")}
      >
        <Radio value="workspace" label="Add all workspace members as reviewers" />
        <Radio mt={4} value="creators" label="Add docket creators as reviewers" />
      </Radio.Group>

      <DatePickerInput
        modalProps={{
          zIndex: 1000,
        }}
        popoverProps={{
          zIndex: 1000,
        }}
        label="Review Due"
        value={dueDate.toDate()}
        onChange={(date) => setDueDate(moment(date))}
      />
      <Box>
        <Button loading={loading} onClick={handleSubmit}>
          Generate Outstanding Pre-bills
        </Button>
      </Box>
    </Stack>
  )
}

export const PreBillTable = memo(() => {
  const router = useRouter()
  const session = useSession()

  const [updateReviewMutation] = useMutation(updateReview)
  const [unbillManyDisbursementsMutation] = useMutation(unbillManyDisbursements)

  const lawyers = lawyerStore.lawyersArray

  const [reviews] = useQuery(getReviews, {
    where: { archived: false, finalized: false },
    take: 1000,
  })

  const _archive = async (__record: Review) => {
    const record = toJS(__record)

    if (__record.archived) {
      await updateReviewMutation({ id: record.id, archived: false })
    } else {
      await unbillManyDisbursementsMutation({ reviewId: record.id })
      await updateReviewMutation({ id: record.id, archived: true })
      invalidateQuery(getReviews)
    }
  }

  const openForm = (record?: Review) => {
    const content = (
      <PreBillForm
        initialValues={record}
        setQueryData={() => {
          invalidateQuery(getReviews)
        }}
      />
    )
    modalStore.setContent("Invoice Form", <div style={{ padding: "24px" }}>{content}</div>)
  }

  const accountantColumns = [
    {
      title: "Complete",
      dataIndex: "complete",
      key: "complete",
      render: (_, record: Review) => (
        <Popconfirm
          title="Convert to a finalized bill?"
          onConfirm={async () => {
            const entries = await invoke(getAllEntries, {
              where: {
                flag: false,
                prebillingReviewId: null,
                matterId: record.matterId,
                date: {
                  lte: moment(record.endDate).toDate(),
                },
                deleted: false,
              },
            })

            if (entries.length > 0) {
              alert("Cannot finalize bill, there are unattached entries.")
              return
            }

            await finalize(record)
            invalidateQuery(getReviews)
          }}
        >
          <Button>
            {/** TODO: Prevent double clicing this.... disable the button */}
            Finalize Bill
          </Button>
        </Popconfirm>
      ),
    },
  ]

  const columns = [
    {
      title: <THeaderPadded>Invoice #</THeaderPadded>,
      dataIndex: "id",
      key: "id",
      render: (value, record) => (
        <THeaderPadded>
          {!isNil(record.pclawExport) ? <TTag>PCLaw</TTag> : null}
          {record.invoiceNo ?? record.id} {record.archived ? <TTag>Archive</TTag> : null}
        </THeaderPadded>
      ),
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: "Client",
      dataIndex: "clientId",
      key: "clientId",
      render: (value: number, record) => (
        <div>{matterStore.findClientFromMatter(record.matterId)?.name}</div>
      ),
      sorter: (a, b) =>
        matterStore.findClientFromMatter(a.matterId)?.id ??
        0 - (matterStore.findClientFromMatter(b.matterId)?.id ?? 0),
    },
    {
      title: "Matter",
      dataIndex: "matterId",
      key: "matterId",
      render: (value: number, record) => <MatterLink value={value} />,
      sorter: (a, b) => a.matterId - b.matterId,
    },
    {
      title: "Docket Range",
      dataIndex: "entryRange",
      key: "entryRange",
      render: (value: [Date, Date], record) => <DocketRangeCell range={value} />,
    },
    {
      title: "Review Due",
      dataIndex: "due",
      key: "due",
      render: (value: Array<number>, record) => <span>{moment(value).format("MMM D, YYYY")}</span>,
      sorter: (a, b) => moment(a.due).diff(moment(b.due), "hour"),
    },
    {
      title: "Reviewers",
      dataIndex: "reviewers",
      key: "reviewers",
      render: (value: Array<{ tier: number; value: number }>, record: Review, index) => (
        <OverlapAvatars
          lawyerIds={value ? value.sort((a, b) => a.tier - b.tier).map((v) => v.value) : []}
          lawyers={lawyers}
          review={record}
        />
      ),
      ...LawyerFilterReview(lawyers),
      // defaultFilteredValue:
      //   // Accountants will see all by default...
      //   session.orgRole === MembershipRole.ACCOUNTANT ? [] : [session.userInOrgId?.toString()],
    },
    {
      title: "Review",
      dataIndex: "review",
      key: "review",
      render: (value, record: Review) => <ReviewButton review={record} />,
    },
    ...(EditingPermissions(session) ? accountantColumns : []),
    session.orgRole !== MembershipRole.USER
      ? RowActions(
          openForm,
          _archive,
          () => false,
          (record) => router.push("/organization/invoices/" + record.id.toString()),
          ActionType.REVIEW,
          (_, record: Review) => {
            return (
              <Button
                onClick={async () => {
                  modalStore.setContent(
                    "Export options",
                    <Box p={24}>
                      <ExportTemplateSection review={record}></ExportTemplateSection>
                    </Box>,
                    true
                  )
                }}
              >
                Export
              </Button>
            )
          }
        )
      : {},
  ]

  const openGenerateModal = () => {
    modals.open({
      title: "Generate outstanding pre-bills",
      children: (
        <Box p={24}>
          <GeneratePreviousMonthModal />
        </Box>
      ),
    })
  }

  const action = (
    <Group>
      <TButton style={{ marginBottom: 8 }} text={"Create Pre-Bill"} onClick={openForm} />
      <TButton
        style={{ marginBottom: 8 }}
        text={"Generate outstanding pre-bills"}
        onClick={openGenerateModal}
      />
    </Group>
  )

  if (!reviews) {
    return <Skeleton height={400} />
  }

  return (
    <div>
      <TTable
        columns={columns as any}
        empty={{
          title: "No pre-bills",
          subtitle: "Create a pre-bill to start finalizing your dockets.",
          action,
        }}
        store={prebillStore}
        dataSource={reviews.reviews}
      />
    </div>
  )
})
