import { openConfirmModal } from "@mantine/modals"
import { Tooltip } from "antd"
import { BillingStatus } from "app/core/components/billing/hooks/helpers/types"
import { useReviewStatus } from "app/core/components/billing/hooks/status"
import { matterStore } from "app/core/stores/store"
import getAllDisbursements from "app/disbursements/queries/getAllDisbursements"
import updateReview from "app/reviews/mutations/updateReview"
import getAllReviews from "app/reviews/queries/getAllReviews"
import { invalidateQuery, invoke, useQuery } from "blitz"
import { Disbursement, Entry } from "db"
import { observer } from "mobx-react-lite"
import { BlueBadge, InfoBadge, WarningBadge } from "../badges/InfoBadge"
import { SuccessBadge } from "../badges/SuccessBadge"

const tooltip = (state: BillingStatus) => {
  switch (state) {
    case BillingStatus.UNBILLED:
      return "Docket is unbilled"
    case BillingStatus.PREBILLED:
      return "Docket is pre-billed (under review)"
    case BillingStatus.FINALIZED:
      return "Docket is billed"
    case BillingStatus.ESTIMATE:
      return "Estimate"
    case BillingStatus.CAN_ADD:
      return "Can add to existing pre-bill"
  }
}

const icon = (state: BillingStatus, invoiceNo?: number | null) => {
  const dimensions = { height: 22, width: 22 }

  switch (state) {
    case BillingStatus.UNBILLED:
      return null
    case BillingStatus.PREBILLED:
      return <InfoBadge title={"Pre-Billed"} />
    case BillingStatus.FINALIZED:
      return <SuccessBadge title={"Billed" + ` ${invoiceNo}`} />
    case BillingStatus.ESTIMATE:
      return <WarningBadge title={"Estimate"} />
    case BillingStatus.CAN_ADD:
      return <BlueBadge title={"Unattached Pre-Bill"} />
    case BillingStatus.COLLECTED:
      return <SuccessBadge title={"Collected" + ` ${invoiceNo}`} />
  }
}

export const BillingStatusCell = observer((props: { record: Entry | Disbursement }) => {
  const [reviews] = useQuery(getAllReviews, { where: { archived: false } })
  const { status, invoiceNo } = useReviewStatus(props.record, reviews)

  const handleClick = async () => {
    if (status === BillingStatus.CAN_ADD) {
      const existingReviews = reviews.filter(
        (r) => r.matterId === props.record.matterId && !r.finalized && !r.archived
      )

      if (existingReviews.length > 0 && props.record.matterId) {
        openConfirmModal({
          title: `We found an existing pre-bill for ${
            matterStore.findMatterFromId(props.record.matterId)?.file
          }`,
          children: `Would you like to add this disbursement to the existing pre-bill?`,
          onConfirm: async () => {
            await invoke(updateReview, {
              id: existingReviews[0].id,
              addDisbursementPrebill: [props.record.id],
            })
            invalidateQuery(getAllDisbursements)
          },
          labels: {
            confirm: "Add",
            cancel: "Skip",
          },
        })
      }
    }
  }

  return (
    <div
      style={{
        minWidth: 100,
        paddingTop: 5,
        cursor: status === BillingStatus.CAN_ADD ? "pointer" : "default",
      }}
      onClick={handleClick}
    >
      <Tooltip title={tooltip(status)}>{icon(status, invoiceNo)}</Tooltip>
    </div>
  )
})
